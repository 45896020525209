/* eslint-disable react/no-danger */

import React, { ReactElement } from 'react';
import { useRouter } from 'next/router';
import { UrlObject, format } from 'url';
import { NextPage, NextPageContext } from 'next';
import Link, { LinkProps } from 'next/link';
import AdSense from 'components/AdSense';
import Async from 'react-async';
import _ from 'lodash';
import withLocale from 'hocs/withLocale';
import useLocale from 'hooks/useLocale';
import {
  getInitialLocale,
} from 'translations/getInitialLocale';
import { ENABLE_ADS } from 'core/configs';
import Layout from 'components/layout';
import Section from 'components/Section';
import { CompactArticleCard, CompactArticleCardLoading } from 'components/ArticleCard';
import TopicsBanner, { TopicsBannerLoading } from 'components/TopicsBanner';
import ErrorAlert from 'components/ErrorAlert';
import { searchArticles } from 'core/services';
import {
  ArticlesSearchQuery, ArticlesSearchResponse, categories, Entity,
} from 'core/types';
import {
  capitalize,
  isServerSide,
  getAsArray,
  getAsCountryArray,
  getAsLanguageArray,
  sameValuesArrays,
  getDaysAgoDate,
  getLinkProps,
  fixOldQuery,
  getAdsProvider,
} from 'core/utils';
import { HOME_HEADER, HOME_FOOTER, SimplePublift } from 'components/Publift';
import Right from '../public/images/icons/right.svg';

interface Props {
  queryTopics: ArticlesSearchQuery;
  topics: ArticlesSearchResponse;
  queries: {
    [key: string]: ArticlesSearchQuery;
  };
  articles: {
    [key: string]: ArticlesSearchResponse;
  };
  queryFactChecking: ArticlesSearchQuery;
  articlesFactChecking: ArticlesSearchResponse;
}

const Home: NextPage<Props> = ({
  queryTopics,
  topics,
  queries,
  articles,
  queryFactChecking,
  articlesFactChecking,
}) => {
  const { t } = useLocale();
  const router = useRouter();
  const adsProvider = getAdsProvider(router.query);

  const getTitle = (): string => {
    const country = getAsCountryArray(getAsArray(router.query.country));
    const countryTitle = country.length === 1 ? t(country[0]) : null;
    const prefix = capitalize(t('knowledia news'));
    return countryTitle ? `${prefix} - ${countryTitle}` : prefix;
  };

  const getMoreLink = (category: string): LinkProps => getLinkProps(router.query, { query: '', category }, '/search');

  const getMoreFactCheckingLink = (): LinkProps => getLinkProps(router.query, { query: '', isFactChecking: '' }, '/search');

  const handleTopicsLoading = (): ReactElement => <TopicsBannerLoading title={t('hot topics')} />;

  const handleTopicsError = (): ReactElement => null;

  const handleTopicsData = (data: ArticlesSearchResponse): ReactElement => (
    <TopicsBanner
      listId="topicsList"
      entities={(data.significant_entities || []).map(
        (entity) => ({ id: entity.entity.id, labels: entity.entity.labels } as Entity),
      )}
      title={t('hot topics')}
    />
  );

  const handleArticlesLoading = (category: string): ReactElement => {
    const block = typeof document !== 'undefined' ? document.getElementById(category) : null;
    if (block) {
      block.classList.remove('hidden');
      block.classList.add('block');
    }

    return (
      <div className="flex flex-wrap px-3 -mx-2 flash-it">
        <div className="flex flex-shrink-0 w-full px-2 mb-4 lg:w-1/2 xl:w-1/3">
          <CompactArticleCardLoading />
        </div>
        <div className="flex flex-shrink-0 w-full px-2 mb-4 lg:w-1/2 xl:w-1/3">
          <CompactArticleCardLoading className="hidden lg:block" />
        </div>
        <div className="flex flex-shrink-0 w-full px-2 mb-4 lg:w-1/2 xl:w-1/3">
          <CompactArticleCardLoading className="hidden xl:block" />
        </div>
      </div>
    );
  };

  const handleArticlesError = (error: Error): ReactElement => <ErrorAlert error={error} />;

  const handleArticlesData = (data: ArticlesSearchResponse, category: string): ReactElement => {
    if (!data.articles || data.articles.length === 0) {
      const block = typeof document !== 'undefined' ? document.getElementById(category) : null;
      if (block) {
        block.classList.remove('block');
        block.classList.add('hidden');
        return null;
      }
    }

    return (
      <div className="flex flex-wrap px-3 -mx-2">
        {data.articles.map((article) => (

          <div className="flex flex-shrink-0 w-full px-2 mb-4 lg:w-1/2 xl:w-1/3">
            <CompactArticleCard key={article.id} article={article} />
          </div>
        ))}
      </div>

    );
  };

  const shouldUpdate = (
    props: Record<string, any>, prevProps: Record<string, any>,
  ): boolean => (
    prevProps.query !== props.query
    || !sameValuesArrays(prevProps.country, props.country)
    || !sameValuesArrays(prevProps.language, props.language)
  );

  return (
    <Layout indexOnlyIfCountry title={getTitle()}>

      <Async
        initialValue={topics}
        promiseFn={searchArticles}
        query={queryTopics.query}
        count={queryTopics.count}
        country={queryTopics.country}
        language={queryTopics.language}
        significantEntities={queryTopics.significantEntities}
        watchFn={shouldUpdate}
      >
        <Async.Pending>{handleTopicsLoading}</Async.Pending>
        <Async.Rejected>{handleTopicsError}</Async.Rejected>
        <Async.Fulfilled>{handleTopicsData}</Async.Fulfilled>
      </Async>

      {ENABLE_ADS && adsProvider === 'publift' ? (
        <SimplePublift fuseId={HOME_HEADER} />
      ) : null}

      {categories.map((category, index) => (
        <>
          {ENABLE_ADS && adsProvider === 'adsense' && index % 2 === 0 ? (
            <div className="block mb-4">
              <AdSense
                className="w-full m-auto mt-4"
                style={{ display: 'block', textAlign: 'center' }}
                format="auto"
                client="ca-pub-0052870555576826"
                slot="5156430740"
                name={`display_home_${index}`}
                fullWidthResponsive="true"
              />
            </div>
          ) : null}

          <div id={category} className="block mb-6">
            <Section title={capitalize(t(category))} headerLevel={2}>
              <Async
                initialValue={articles[category]}
                promiseFn={searchArticles}
                query={queries[category].query}
                cursor={queries[category].cursor}
                count={queries[category].count}
                field={queries[category].field}
                category={queries[category].category}
                country={queries[category].country}
                language={queries[category].language}
                significantEntities={queries[category].significantEntities}
                watchFn={shouldUpdate}
              >
                <Async.Pending>
                  {(): ReactElement => handleArticlesLoading(
                    category as string,
                  )}
                </Async.Pending>
                <Async.Rejected>{handleArticlesError}</Async.Rejected>
                <Async.Fulfilled>
                  {(data: ArticlesSearchResponse): ReactElement => handleArticlesData(
                    data, category as string,
                  )}
                </Async.Fulfilled>
              </Async>
              <Link href={getMoreLink(category).href} as={getMoreLink(category).as}>
                <a className="px-3">
                  <div className="inline-flex items-center button">
                    <span>{capitalize(t('more articles'))}</span>
                    {' '}
                    <Right className="inline-block w-4 h-4 ml-1 fill-current" />
                  </div>
                </a>
              </Link>
            </Section>
          </div>
        </>
      ))}

      <div id="fact-checking" className="block mb-6">
        <Section title={capitalize(t('fact-checking'))} headerLevel={2}>
          <Async
            initialValue={articlesFactChecking}
            promiseFn={searchArticles}
            query={queryFactChecking.query}
            count={queryFactChecking.count}
            field={queryFactChecking.field}
            country={queryFactChecking.country}
            language={queryFactChecking.language}
            isFactChecking={queryFactChecking.isFactChecking}
            watchFn={shouldUpdate}
          >
            <Async.Pending>
              {(): ReactElement => handleArticlesLoading(
                'fact-checking',
              )}
            </Async.Pending>
            <Async.Rejected>{handleArticlesError}</Async.Rejected>
            <Async.Fulfilled>{(data: ArticlesSearchResponse): ReactElement => handleArticlesData(data, 'fact-checking')}</Async.Fulfilled>
          </Async>
          <Link href={getMoreFactCheckingLink().href} as={getMoreFactCheckingLink().as}>
            <a className="px-3">
              <div className="inline-flex items-center button">
                <span>{capitalize(t('more articles'))}</span>
                {' '}
                <Right className="inline-block w-4 h-4 ml-1 fill-current" />
              </div>
            </a>
          </Link>
        </Section>
      </div>

      {
        ENABLE_ADS && adsProvider === 'adsense'
          ? (
            <div className="block mb-4">
              <AdSense
                className="w-full m-auto mt-4"
                style={{ display: 'block', textAlign: 'center' }}
                format="auto"
                client="ca-pub-0052870555576826"
                slot="5156430740"
                name="display_home_bottom"
                fullWidthResponsive="true"
              />
            </div>
          ) : null
      }

      {ENABLE_ADS && adsProvider === 'publift' ? (
        <SimplePublift fuseId={HOME_FOOTER} />
      ) : null}

    </Layout>
  );
};

const getQuery = (): string => `creation_date:[${getDaysAgoDate(7).toISOString()} TO *]`;

const getTopicsQuery = (): string => `creation_date:[${getDaysAgoDate(3).toISOString()} TO *]`;

const handleRedirect = async (ctx: NextPageContext): Promise<void> => {
  if (isServerSide()) {
    if (ctx.req.url === '/' || ctx.req.url.startsWith('/?')) {
      fixOldQuery(ctx);
      const location = getLinkProps(ctx.query, await getInitialLocale(ctx), '/').as as UrlObject;
      ctx.res.writeHead(ctx.req.url === '/' ? 302 : 301, { Location: `${format(location)}` });
      ctx.res.end();
    }
  }
};

Home.getInitialProps = async (ctx: NextPageContext): Promise<Props> => {
  handleRedirect(ctx);

  const language = getAsLanguageArray(getAsArray(ctx.query.contentLanguage));
  const country = getAsCountryArray(getAsArray(ctx.query.country));

  const queries = {};
  for (const category of categories) {
    queries[category] = {
      query: getQuery(),
      category,
      country,
      language,
      count: 6,
      field: [
        'id',
        'creation_date',
        'title',
        'url',
        'hostname',
        'images',
        'labels',
        'is_fact_checking',
      ],
    };
  }

  const queriesTopics = {};
  for (const category of categories) {
    queriesTopics[category] = {
      query: getTopicsQuery(), category, country, language, count: 0, significantEntities: 20,
    };
  }

  const queryFactChecking = {
    query: getQuery(),
    country,
    language,
    isFactChecking: true,
    count: 6,
    field: [
      'id',
      'creation_date',
      'title',
      'url',
      'hostname',
      'images',
      'labels',
      'is_fact_checking',
    ],
  };

  let articles = {};
  const queryTopics = {
    query: getTopicsQuery(),
    country,
    language,
    count: 0,
    significantEntities: 20,
  };
  let topics = null;
  let articlesFactChecking = null;

  if (isServerSide()) {
    for (const category of categories) {
      articles[category] = searchArticles<ArticlesSearchResponse>(queries[category]);
    }

    await Promise.all(
      _.values(articles).concat([
        searchArticles<ArticlesSearchResponse>(queryTopics),
      ]).concat([
        searchArticles<ArticlesSearchResponse>(queryFactChecking),
      ]),
    ).then((results) => {
      articles = _.zipObject(_.keys(articles), results.slice(0, _.keys(articles).length));
      topics = results[results.length - 2];
      articlesFactChecking = results[results.length - 1];
    });
  }

  return {
    queryTopics,
    topics,
    queries,
    articles,
    queryFactChecking,
    articlesFactChecking,
  };
};

export default withLocale(Home);
